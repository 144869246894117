.Container{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 150px;
    display: inline-block;
    color: #0084FF;
}
.Container h5{
    padding: 20px 0;
}
.Loader{
    position: relative;
    margin-top: 50px;
}
