.VerifiedBadge {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    padding: 1px 0 1px 40px;
    margin: auto;
    display: inline-block;
}

.VerifiedBadge span {
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 24px;
}

.VerifiedBadge span:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 6px;
    opacity: 1;
    position: absolute;
    top: 8px;
    transform: rotate(-50deg);
    width: 12px;
}