.Container {
    width: 100%;
    max-height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
}

.Picture{
    border-radius: 50%;
}


