.vendor {
    border-top: 1px solid #eee;
    padding: 8px 12px;
}

.logo {
    height: 77px;
    border-radius: 4px;
}

.logo img {
    object-fit: contain;
}

.name h5 {
    margin: 0;
    padding: 0;
    font-style: medium;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.385882px;
}

.star {
    color: #FDB245;
    font-size: 10px;
    padding: 0 1px;
}

.data {
    padding: 0 0 0 12px;
}

.details {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.reviews {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.34);
    padding-left: 3px;
}