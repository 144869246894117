.Container {
    text-align: center;
    direction: rtl;
}

.Container img {
    margin-top: 10px;
}

.Container p {
    margin-top: 10px;
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-family: 'Cairo', tahoma;
    font-weight: bold;
}

.Container button {
    border: none;
    background-color: #0084FF;
    border-radius: 20px;
    color: white;
    padding: 2px 32px;
    margin: 10px 20px;
    font-size: 15px;
    font-family: 'Cairo', tahoma;
}

.Container .Countdown{
    color: #999;
    font-size: 15px;
    font-weight: lighter;
}