.Time {
    position: relative;
}

.Time span {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    pointer-events: none;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 0px 12px;
    font-style: normal;
    color: rgba(0, 0, 0, 0.2);
    font-weight: normal;
    font-size: 17px;
    height: 100%;
    line-height: 36px;
    background-color: white;
}