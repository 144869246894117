.vendors {
    margin-bottom: 64px;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    z-index: 9999;
    padding: 8px;
    text-align: center;
    background-color: #FFFFFF;
}

header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
    text-align: center;
    background-color: #FFFFFF;
}

.more {
    height: 48px;
    width: 100%;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 32px;
    max-width: 359px;
    background: #0084FF;
    transition: all 0.1s ease-in-out;
    font-weight: 500;
}

.more:hover, .more:active {
    background: #0074E0;
    color: #FFFFFF;
}
