.loader {
    border-width: 4px;
    border-color: #0084FF;
    border-style: solid;
    border-top-color: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}