.Container {
    text-align: right;
    direction: rtl;
}

.form {
    margin-bottom: 64px;
}

.Container header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
    text-align: inherit;
}

.Container footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    z-index: 9999;
    padding: 8px;
    text-align: center;
    background-color: #FFFFFF;
}

.Submit {
    border: none;
    height: 48px;
    padding: 0;
    width: 100%;
    font-style: normal;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 48px;
    max-width: 359px;
    background: #0084FF;
    transition: all 0.1s ease-in-out;
}

.Submit img {
    height: 24px;
    padding: 0 8px;
}

.Submit:hover {
    background: #0074E0;
    color: #FFFFFF;
}

.Container input, .Container textarea, .Container select {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 0px 12px;
    outline: none;
    background: white;
    transition: border-color 0.2s ease-in-out;
    width: 100%;
    font-size: 16px;
    min-height: 38px;
}

.Container textarea {
    padding-top: 4px;
    padding-bottom: 4px;
    resize: none;
}

.Error {
    color: red;
    text-align: center;
}