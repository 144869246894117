.Label{
    font-weight: 500;
}
.Radio {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    margin-top: 0px ;
    cursor: pointer;
    font-weight: 500;
}

.Radio span {
    background-color: #fff;
    border: 2px solid #0084FF;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    display: inline-block;
    width: 24px;
    vertical-align: middle;
    margin: 0 10px;
}

.Radio span:after {
    border: 2px solid #fff;
    content: "";
    border-radius: 50%;
    height: 18px;
    background-color: #0084FF;
    opacity: 0;
    width: 18px;
    margin: 1px;
    display: inline-block;
}

.Radio input[type="radio"] {
   position: absolute;
   opacity: 0;
   left: 12px;
}

.Radio input[type="radio"]:checked+span {
    background-color: #FFF;
}

.Radio input[type="radio"]:checked+span:after {
    opacity: 1;
}