.Checkbox {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
}

.Checkbox span {
    background-color: #fff;
    border: 2px solid #0084FF;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
}

.Checkbox p{
    display: inline-block;
    margin: 0 10px;
}

.Checkbox span:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    transform: rotate(-50deg);
    width: 12px;
    margin: 3px 4px;
    display: inline-block;
}

.Checkbox input[type="checkbox"] {
    visibility: hidden;
    width: 0;
}

.Checkbox input[type="checkbox"]:checked+span {
    background-color: #0084FF;
}

.Checkbox input[type="checkbox"]:checked+span:after {
    opacity: 1;
}