  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    overflow: auto;
    top: 38px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    margin-bottom: 70px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    max-height: 180px;
    list-style-type: none;
  }

  .react-autosuggest__suggestion span {
    cursor: pointer;
    padding: 8px 20px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #0084FF;
    color: white;
  }