.Container {
    text-align: center;
    direction: rtl;
}

.Container img {
    margin-top: 10px;
}

.Container p {
    margin-top: 10px;
    font-size: 28px;
    padding: 0 20px;
    color: #D5204C;
    font-family: 'Cairo', tahoma;
    font-weight: bold;
}

.Options{
    margin-top: 60px;
}

.Options button {
    border: none;
    background-image: linear-gradient(to left, #D64571, #F67821);
    border-radius: 20px;
    color: white;
    padding: 2px 32px;
    margin: 10px 20px;
    font-size: 20px;
    font-family: 'Cairo', tahoma;
}
