.Input input,.Input textarea,.Input select {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 6px 12px;
    outline: none;
    background: white;
    transition: border-color 0.2s ease-in-out;
    width: 100%;
}

.Input input[type="date"], input[type="time"] {
    height: 38px;
}

.LocationIcon {
    min-height: 38px;
    background-color: #0084FF;
    line-height: 38px;
    border-radius: 4px;
    min-width: 50px;
    font-size: 25px;
    text-align: center;
    color: white;
    transition: all 0.2s ease-in-out;
}

.LocationIcon:hover, .LocationIcon:active {
    background: #0074E0;
    color: #FFFFFF;
}

.LocationIcon:disabled {
    background: rgba(0, 0, 0, 0.06);
    color: rgba(41, 25, 25, 0.34);
}

.Input input[type="number"] {
    -moz-appearance: textfield;
}

.Input input::-webkit-outer-spin-button,.Input input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.Input select {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: black;
    font-weight: 500;
    outline: none;
}

.Input input::placeholder,.Input textarea::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.2);
}

.numberButton {
    height: 100%;
    border-radius: 4px;
    color: #0084FF;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 25px;
    line-height: 22px;
    transition: all 0.1s ease-in-out;
}

.numberButton:hover, .numberButton:active {
    color: #CCE6FF;
    border-color: #CCE6FF;
}

.option {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: black;
    font-weight: 500;
    outline: none;
}