.Button{
    border: none;
    border-radius: 4px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 14px;
    background: #0084FF;
    padding: 8px 16px;
    transition: all 0.2s ease-in-out;
    height: 100%;
    max-height: 38px;
    white-space: nowrap;
}

.Button:hover,.Button:active {
    background: #0074E0;
    color: #FFFFFF;
}

.Button:disabled{
    background: rgba(0, 0, 0, 0.06);
    color: rgba(41, 25, 25, 0.34);
}