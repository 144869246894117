.Container {
    text-align: right;
    margin: 0;
    height: auto;
}

.Switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 20px;
    margin: 0;
}

.Switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0084FF;
    -webkit-transition: .4s;
    transition: .4s;
}

.Slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.Slider {
    background-color: #0084FF;
}

input:checked+.Slider:before {
    -webkit-transform: translateX(-36px);
    -ms-transform: translateX(-36px);
    transform: translateX(-36px);
}

/* Rounded sliders */

.Slider {
    padding: 0 6px;
    border-radius: 34px;
    color: white;
    text-align: left;
    line-height: 20px;
    font-size: 14px;
}

.Slider:before {
    border-radius: 50%;
}